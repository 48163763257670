import { gql } from "@apollo/client"

/**
 * GraphQL categories query.
 */
const GET_CATEGORIES_QUERY = ({ imageSize }) => gql`
  {
    productCategories(first: 4) {
      edges {
        node {
          id
          name
          slug
          image {
            sourceUrl(size: ${imageSize})
            srcSet(size: ${imageSize})
            sizes
            altText
          }
        }
      }
    }
  }
`

export default GET_CATEGORIES_QUERY
