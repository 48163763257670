import classes from "../assets/styles/modules/Loader.module.scss";
import logo from "../assets/images/logo_white.svg"

export default function Loader({ isVisible = true, isBlue = true }) {
  return (
    isVisible && (
      <div
        className={`${classes.loader} ${
          isBlue ? classes.loader_blue : classes.loader_dark
        }`}
      >
        <div className={classes.loader__image}>
          <img src={logo} alt="Loader" />
        </div>
      </div>
    )
  );
}
