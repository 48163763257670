import { createContext, useMemo, useState } from "react";
import Modal from "../components/Modal";

export const ModalContext = createContext();

export default function ModalContextProvider({ children }) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => setIsModalOpened(false);

  const closeModalHandler = () => {
    setIsClosing(true);
    const timeout = setTimeout(() => {
      closeModal();
      setIsClosing(false);
      clearTimeout(timeout);
    }, 300);
  };

  const value = useMemo(
    () => ({
      isModalOpened,
      openModal,
      closeModal,
      isClosing,
      closeModalHandler,
    }),
    // eslint-disable-next-line
    [isModalOpened, isClosing]
  );

  return (
    <ModalContext.Provider value={value}>
      {isModalOpened && <Modal></Modal>}
      { children }
    </ModalContext.Provider>
  );
}
