import { gql } from "@apollo/client"
import { product } from "./common"

/**
 * GraphQL product query.
 */
const GET_PRODUCT_QUERY = (slug = "") => gql`
  {
    product(id: "${slug}", idType: SLUG) {
      ${product}
      description(format: RENDERED)
      galleryImages {
        edges {
          node {
            id
            mediaItemUrl
            sourceUrl
            sizes
          }
        }
      }
      attributes {
        edges {
          node {
            id
            name
            options
          }
        }
      }
      productTags {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export default GET_PRODUCT_QUERY
