import { v4 as uuid } from "uuid";

export const sortOptions = [
  {
    id: uuid(),
    label: "Newness",
    options: [{ field: "DATE", order: "DESC" }],
  },
  {
    id: uuid(),
    label: "Average rating",
    options: [{ field: "RATING", order: "DESC" }],
  },
  {
    id: uuid(),
    label: "Popularity",
    options: [{ field: "TOTAL_SALES", order: "DESC" }],
  },
];
