import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loader from "./components/Loader";

const Layout = lazy(() => import("./components/Layout"));
const Home = lazy(() => import("./pages/home"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Products = lazy(() => import("./pages/products"));
const Product = lazy(() => import("./pages/product"));
const Blog = lazy(() => import("./pages/blog"));
const Post = lazy(() => import("./pages/post"));
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy"));
const RefundPolicy = lazy(() => import("./pages/refund-policy"));
const TermsOfService = lazy(() => import("./pages/terms-of-service"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const SignIn = lazy(() => import("./pages/sign-in"));
const SignUp = lazy(() => import("./pages/sign-up"));
const Cart = lazy(() => import("./pages/cart"));

export default function Router() {
  const renderLoader = () => <Loader/>;

  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="products" element={<Products />} />
          <Route path="products/:slug" element={<Product />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:slug" element={<Post />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="cart" element={<Cart />} />
          <Route path="*" element={<Navigate to="/page-not-found" />} />
        </Route>
        <Route path="/page-not-found" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
