import { gql } from "@apollo/client";

/**
 * GraphQL page query.
 */
export const GET_PAGE = (slug = "") => gql`
 {
   page(id: "${slug}", idType: URI) {
     title
     content
     seo {
        fullHead
      }
   }
 }
`;
