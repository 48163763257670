import { gql } from "@apollo/client";

/**
 * GraphQL brands query.
 */
const GET_BRANDS_QUERY = gql`
  {
    brands(first: 3) {
      edges {
        node {
          id
          name
          slug
          description
        }
      }
    }
  }
`;

export default GET_BRANDS_QUERY;
