import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import GlobalContextProvider from "./context/globalContext";
import { HelmetProvider } from "react-helmet-async";
import "./assets/styles/index.scss";
import ModalContextProvider from "./context/ModalContext";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_WORDPRESS_API_URL}/graphql`,
  cache: new InMemoryCache({ addTypename: false }),
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <GlobalContextProvider>
          <ModalContextProvider>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ModalContextProvider>
        </GlobalContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
