import { createContext, useMemo, useState } from "react"
import GET_PRODUCTS_QUERY from "../queries/products"
import GET_PRODUCT_QUERY from "../queries/product"
import GET_CATEGORIES_QUERY from "../queries/categories"
import GET_BRANDS_QUERY from "../queries/brands"
import {useApolloClient} from "@apollo/client"
import { GET_POST, GET_POSTS } from "../queries/blog"
import { GET_PAGE } from "../queries/page"
import GET_COLORS_QUERY from "../queries/colors"
import { sortOptions } from "../components/products/SortOptions"

export const GlobalContext = createContext()

export default function GlobalContextProvider({ children }) {
  const client = useApolloClient()

  const [products, setProducts] = useState({
    edges: [],
    pageInfo: {},
    query: "",
  })
  const [product, setProduct] = useState({})
  const [post, setPost] = useState(null)
  const [page, setPage] = useState({})
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [colors, setColors] = useState([])
  const [formResponseStatus, setFormResponseStatus] = useState(null)
  const [posts, setPosts] = useState({
    edges: [],
    pageInfo: {},
    query: "",
  })
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    after: "",
  })
  const [sortList, setSortList] = useState(sortOptions[0])
  const [filtersList, setFiltersList] = useState({
    categories: [],
    brands: [],
    colors: [],
  })
  const [query, setQuery] = useState("")
  const [isNavFilter, setIsNavFilter] = useState(false)
  const [total, setTotal] = useState(null)

  const sendForm = async (formId, data) => {
    const apiURL = process.env.REACT_APP_WORDPRESS_API_URL
    try {
      setLoading(true)
      const formData = new FormData()
      for (let name in data) {
        formData.append(name, data[name])
      }
      const res = await fetch(
        `${apiURL}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`,
        { method: "POST", body: formData }
      )

      setFormResponseStatus(res.status)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const getColors = async () => {
    const {
      data: { paColors },
    } = await client.query({
      query: GET_COLORS_QUERY,
    })

    if (paColors) {
      setColors(paColors.nodes)
    }
  }

  const getBrands = async () => {
    const {
      data: { brands },
    } = await client.query({
      query: GET_BRANDS_QUERY,
    })

    if (brands) {
      setBrands(brands.edges)
    }
  }

  const getCategories = async props => {
    const {
      data: { productCategories },
    } = await client.query({
      query: GET_CATEGORIES_QUERY(props),
    })

    if (productCategories) {
      setCategories(productCategories.edges)
    }
  }

  const getPosts = async props => {
    try {
      setLoading(true)
      const {
        data: { posts },
      } = await client.query({
        query: GET_POSTS(props),
      })

      setPosts(prevState => {
        return {
          edges:
            prevState.query === props.query
              ? [
                  ...new Set(
                    [...prevState.edges, ...posts.edges].map(JSON.stringify)
                  ),
                ].map(JSON.parse)
              : posts.edges,
          pageInfo: posts.pageInfo,
          query: props.query,
        }
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const getProducts = async props => {
    try {
      console.log("client.cache 1", client.cache)
      setLoading(true)
      const {
        data: { products },
      } = await client.query({
        query: GET_PRODUCTS_QUERY(props),
      })

      console.log("getProducts", products)
      console.log("client.cache 2", client.cache)

      setProducts(prevState => {
        return {
          edges:
            prevState.query === props.query
              ? [
                  ...new Set(
                    [...prevState.edges, ...products.edges].map(JSON.stringify)
                  ),
                ].map(JSON.parse)
              : products.edges,
          pageInfo: products.pageInfo,
          query: props.query,
        }
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const getProduct = async slug => {
    try {
      const {
        data: { product },
      } = await client.query({
        query: GET_PRODUCT_QUERY(slug),
      })

      if (product) {
        setProduct(product)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getPage = async slug => {
    try {
      const {
        data: { page },
      } = await client.query({
        query: GET_PAGE(slug),
      })

      if (page) {
        setPage(page)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getPost = async slug => {
    try {
      setLoading(true)
      const {
        data: { post },
      } = await client.query({
        query: GET_POST(slug),
      })

      if (post) {
        setPost(post)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const value = useMemo(
    () => ({
      getBrands,
      brands,
      getCategories,
      categories,
      getProducts,
      products,
      getProduct,
      product,
      posts,
      getPosts,
      getPost,
      post,
      sendForm,
      getPage,
      page,
      colors,
      getColors,
      loading,
      params,
      setParams,
      setProducts,
      setPosts,
      formResponseStatus,
      setFormResponseStatus,
      sortList,
      setSortList,
      filtersList,
      setFiltersList,
      query,
      setQuery,
      isNavFilter,
      setIsNavFilter,
      total,
      setTotal,
    }),
    // eslint-disable-next-line
    [
      categories,
      products,
      brands,
      product,
      posts,
      post,
      page,
      colors,
      loading,
      params,
      formResponseStatus,
      sortList,
      filtersList,
      query,
      isNavFilter,
      total,
    ]
  )
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}
