import classes from "../assets/styles/modules/Modal.module.scss";
import btnClasses from "../assets/styles/modules/Button.module.scss";
import { ReactComponent as Arrow } from "../assets/images/arrow.svg";
import { useContext, useEffect } from "react";
import { ModalContext } from "../context/ModalContext";
import { GlobalContext } from "../context/globalContext";

const styles = {
  button: `${btnClasses.button} ${btnClasses.button_white}`,
};

export default function Modal() {
  const { setFormResponseStatus } = useContext(GlobalContext);

  const { isClosing, closeModalHandler, isModalOpened } =
    useContext(ModalContext);
  useEffect(() => {
    isModalOpened && (document.body.style.overflow = "hidden");
    return () => (document.body.style.overflow = "");
  }, [isModalOpened]);
  return (
    <section
      className={`${classes.modal} ${isClosing ? classes.modal__hide : ""}`}
    >
      <div className={classes.body}>
        <button
          onClick={() => {
            closeModalHandler();
            setFormResponseStatus(null);
          }}
          className={classes.close}
        >
          close
        </button>
        <h2 className={classes.title}>
          <span>You</span> are on the waiting list.{" "}
        </h2>
        <h3 className={classes.subtitle}>you will be contacted soon</h3>
        <button
          onClick={() => {
            closeModalHandler();
            setFormResponseStatus(null);
          }}
          className={styles.button}
        >
          Ok <Arrow />
        </button>
      </div>
    </section>
  );
}
