import { gql } from "@apollo/client";
import { post } from "./common";

/**
 * GraphQL blog query.
 */
export const GET_POSTS = ({ after = "", perPage = "16" }) => gql`
  query Posts {
    posts(first: ${perPage}, after: "${after}") {
      edges {
        cursor
        node {
          id
          ${post}
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * GraphQL blog query.
 */
export const GET_POST = (slug = "") => gql`
 {
   post(id: "${slug}", idType: SLUG) {
     ${post}
     content
     seo {
        fullHead
      }
   }
 }
`;
