import { useContext, useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { GlobalContext } from "./context/globalContext";
import { ModalContext } from "./context/ModalContext";
import Router from "./router";

function App() {
  const { openModal } = useContext(ModalContext);
  const { formResponseStatus } = useContext(GlobalContext);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (formResponseStatus === 200) {
      openModal(true);
    }
  }, [formResponseStatus]);

  return <Router />;
}

export default App;
