export const product = `
  databaseId
  name
  image {
    sourceUrl
    altText
  }
  shortDescription(format: RENDERED)
  productCategories {
    edges {
      node {
        id
        name
        slug
      }
    }
  }
 brands{
   edges {
     node {
       id
       name
     }
   }
  }
`

export const post = `
  title
  date
  slug
  excerpt
  featuredImage {
    node {
      mediaItemUrl
    }
  }
`
