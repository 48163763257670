import { gql } from "@apollo/client";

const GET_COLORS_QUERY = gql`
  {
    paColors {
        nodes {
          slug
          name
          paColorId
        }
      }
  }
`;

export default GET_COLORS_QUERY;
