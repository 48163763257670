import { gql } from "@apollo/client"
import { product } from "./common"

/**
 * GraphQL products query.
 *
 * Filter by categories, brands, taxonomies.
 * Order by DATE, PRICE (ASC, DESC)
 *
 * Exist brands: fast-buds, prolific-coast-seeds, puffco
 * Exist categories: accessories, autoflowering-seeds, original-line-seeds, regular-seeds
 * Exist taxonomies: breeder, color, cultivation, flavor, gender, seeds-in-pack, strain
 * Exist color terms: Beige, Black, Blue, Brown, Green, Light, Orange, Pink, Purple, Red, White,
 */

const objectToStringAndReplaceQuotes = object =>
  JSON.stringify(object).replaceAll('"', "").replaceAll("'", '"')

const GET_PRODUCTS_QUERY = ({
  after = "",
  perPage = 12,
  filters = null,
  orderBy = null,
  exclude = null,
}) => gql`
  {
    products(
      first: ${perPage},
      after: "${after}"
      where: {
        ${exclude ? `exclude: ${exclude}` : ""}
        ${orderBy ? `orderby: ${objectToStringAndReplaceQuotes(orderBy)},` : ""}
        ${
          filters
            ? `taxonomyFilter: { filters: ${objectToStringAndReplaceQuotes(
                filters
              )} }`
            : ""
        }
      }
    ) {
      edges {
        cursor
        node {
          slug
          image {
            sourceUrl
            srcSet
            sizes
            altText
          }
          ${product}
          ... on VariableProduct {
            salePrice
            price
            regularPrice
            sku
            variations {
              nodes {
                regularPrice
                sku
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        total
      }
    }
  }
`

export default GET_PRODUCTS_QUERY
